import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['drawer', 'slideOverPanel'];

  static outlets = ['bulk-edit'];

  open() {
    this.slideOverPanelTarget.classList.remove('translate-x-full');
    this.slideOverPanelTarget.classList.add('translate-x-0');
    document.addEventListener('click', this.#handleClickOutside.bind(this));
  }

  close() {
    this.slideOverPanelTarget.classList.remove('translate-x-0');
    this.slideOverPanelTarget.classList.add('translate-x-full');
    document.removeEventListener('click', this.#handleClickOutside.bind(this));
  }

  #handleClickOutside({ target }) {
    if (!this.element.contains(target) || (!this.slideOverPanelTarget.contains(target)
      && this.hasBulkEditOutlet
      && target !== this.bulkEditOutlet.editButtonTarget.firstElementChild)
    ) {
      this.close();
    }
  }
}
