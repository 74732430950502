import Sortable from '@stimulus-components/sortable';
import { patch } from '@rails/request.js';

export default class extends Sortable {
  static targets = ['lockVersion'];

  async onUpdate({ item, newIndex }) {
    if (!item.dataset.sortableUpdateUrl) return;

    const param = this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue;

    const data = new FormData();
    data.append(param, newIndex + 1);
    if (this.hasLockVersionTarget) {
      data.append('lock_version', this.lockVersionTarget.value);
    }

    const response = await patch(item.dataset.sortableUpdateUrl, { body: data, responseKind: 'turbo-stream' });
    if (response.statusCode >= 400) {
      this.dispatch('error');
    }
  }
}
