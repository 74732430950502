import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    memberId: String,
    clientId: String,
  };

  connect() {
    const buttons = document.querySelectorAll(
      `button[data-expandable-planning-client-member-id-value="${this.memberIdValue}"]`
      + `[data-expandable-planning-client-client-id-value="${this.clientIdValue}"]`,
    );
    buttons.forEach((button) => {
      button.addEventListener('mouseenter', (event) => this.handleHover(event));
      button.addEventListener('mouseleave', (event) => this.handleHover(event));
    });
  }

  handleHover(event) {
    const buttons = document.querySelectorAll(
      `button[data-expandable-planning-client-member-id-value="${this.memberIdValue}"]`
      + `[data-expandable-planning-client-client-id-value="${this.clientIdValue}"]`,
    );
    buttons.forEach((button) => {
      if (event.type === 'mouseenter') {
        if (button.classList.contains('is-current-week')) {
          button.classList.replace('bg-opacity-30', 'bg-opacity-50');
        } else {
          button.classList.replace('bg-white', 'bg-slate-50');
        }
      } else if (event.type === 'mouseleave') {
        if (button.classList.contains('is-current-week')) {
          button.classList.replace('bg-opacity-50', 'bg-opacity-30');
        } else {
          button.classList.replace('bg-slate-50', 'bg-white');
        }
      }
    });
  }

  toggleExpandablePlanningClient(_event) {
    this.collapseClientIcon = document
      .getElementById(`collapse-client-icon-${this.memberIdValue}-${this.clientIdValue}`);
    this.expandClientIcon = document
      .getElementById(`expand-client-icon-${this.memberIdValue}-${this.clientIdValue}`);

    this.collapseClientIcon.classList.toggle('hidden');
    this.expandClientIcon.classList.toggle('hidden');

    if (this.expandClientIcon.classList.contains('hidden')) {
      this.fireTurboRequestToLoadProjects();
    } else {
      this.removeProjectsFromPage();
    }
  }

  fireTurboRequestToLoadProjects() {
    const loadProjectsTurboLink = document
      .getElementById(`load-projects-for-member-${this.memberIdValue}-and-client-${this.clientIdValue}`);

    if (loadProjectsTurboLink) loadProjectsTurboLink.click();
  }

  removeProjectsFromPage() {
    this.eraseProjectsTotal();
    this.eraseProjectsNames();
    this.eraseProjectsFirstColumn();
    this.clearProjectsForMemberAndClient();
  }

  eraseProjectsTotal() {
    const totalsWeek = document
      .getElementById(`projects-total-week-member-${this.memberIdValue}-client-${this.clientIdValue}`);
    if (totalsWeek) totalsWeek.innerHTML = '';
  }

  eraseProjectsNames() {
    const projects = document
      .querySelectorAll(`.project-for-member-${this.memberIdValue}-client-${this.clientIdValue}`);

    Array.from(projects).forEach((project) => {
      // eslint-disable-next-line no-param-reassign
      project.innerHTML = '';
    });
  }

  eraseProjectsFirstColumn() {
    const firstColumn = document
      .getElementById(`first-column-for-team-member-${this.memberIdValue}-and-client-${this.clientIdValue}`);
    if (firstColumn) firstColumn.innerHTML = '';
  }

  clearProjectsForMemberAndClient() {
    const clearProjectsForMemberAndClientLink = document.getElementById(
      `clear-projects-for-member-${this.memberIdValue}-and-client-${this.clientIdValue}`,
    );
    if (clearProjectsForMemberAndClientLink) clearProjectsForMemberAndClientLink.click();
  }
}
