import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['avatar'];

  static values = {
    avatarComponentUrl: String,
  };

  replace(event) {
    const memberId = event.target.value;

    fetch(`${this.avatarComponentUrlValue}/${memberId}`)
      .then((response) => response.text())
      .then((html) => {
        this.avatarTarget.innerHTML = html;
      });
  }
}
