import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['layoutContainer'];

  connect() {
    this.restoreScrollPosition();
  }

  restoreScrollPosition() {
    if (!this.layoutContainerTarget) {
      return;
    }

    const scrollPosition = sessionStorage.getItem('layoutContainerScrollPosition');
    if (scrollPosition === null) {
      return;
    }

    const scrollPos = parseInt(scrollPosition, 10);
    if (Number.isNaN(scrollPos)) {
      return;
    }

    setTimeout(() => {
      this.layoutContainerTarget.scrollTo({ top: scrollPos, behavior: 'smooth' });
      sessionStorage.removeItem('layoutContainerScrollPosition');
    }, 300);
  }
}
