import { Controller } from '@hotwired/stimulus';
import { DateTime } from 'luxon';

export default class extends Controller {
  static targets = [
    'filters', 'filter', 'filterOptions', 'back', 'headerText', 'optionsSummary', 'filterOption', 'dueFrom', 'dueTo',
    'error', 'errors', 'submit', 'filterSearch', 'filterOptionRow', 'activeFilters', 'activeFilter', 'olderThan',
  ];

  static values = { connected: Boolean };

  connect() {
    this.connectedValue = true;
  }

  initialize() {
    this.currentFilterView = '';
    this.chosenOptions = {};
    this.filterOptionsTargets.forEach((option) => {
      this.chosenOptions[option.dataset.filterName] = [];
    });
    this.filterOptionTargets.forEach((option) => {
      if (option.checked) {
        this.chosenOptions[option.dataset.filterFilterParam].push(option.dataset.filterTextParam);
      }
    });
    this.initializeFilterSummary();
  }

  deselectOption(event) {
    const { filter, text } = event.params;
    const index = this.chosenOptions[filter].indexOf(String(text));

    if (index !== -1) {
      const checkbox = this.checkboxes.find((option) => option.dataset.filterFilterParam === filter
        && option.dataset.filterTextParam === String(text));
      checkbox.checked = false;

      this.chosenOptions[filter].splice(index, 1);
    }
  }

  toggleActiveFilter(event) {
    const { filter, text } = event.params;
    const filterIsActive = this.chosenOptions[filter].includes(text);

    if (filterIsActive) {
      const idx = this.chosenOptions[filter].indexOf(text);
      const checkbox = this.checkboxes.find((option) => option.dataset.filterTextParam === text);

      this.chosenOptions[filter].splice(idx, 1);
      checkbox.checked = false;
    } else {
      this.chosenOptions[filter].push(text);
    }

    if (this.hasActiveFiltersTarget) {
      const foundActiveFilter = this.activeFilterTargets.find((activeFilter) => (
        activeFilter.dataset.filterIdParam === text
        && activeFilter.dataset.filterFilterParam === filter
      ));
      if (typeof foundActiveFilter !== 'undefined') {
        foundActiveFilter.classList.toggle('hidden');
      }
    }
  }

  toggleOlderThanFilter(event) {
    const { text, filter } = event.params;
    const filterIsActive = this.chosenOptions[filter].includes(text);
    const daysValueEmpty = !this.olderThanTarget.value;

    if (filterIsActive && daysValueEmpty) {
      this.addError('Days must not be blank');
    } else if (!filterIsActive) {
      this.removeErrors();
      this.olderThanTarget.value = '';
    }
  }

  search() {
    const currSearch = this.currentSearchValue();

    this.filterOptionRowTargets.forEach((row) => {
      if (this.rowIsInCurrentFilterView(row)) {
        if (currSearch === '' || row.dataset.filterOptionText.toLowerCase().includes(currSearch)) {
          row.classList.remove('hidden');
        } else {
          row.classList.add('hidden');
        }
      }
    });
  }

  rowIsInCurrentFilterView(row) {
    return this.currentFilterView === row.dataset.optionRowFilterName;
  }

  currentSearchValue() {
    let result = '';
    this.filterSearchTargets.forEach((searchbox) => {
      if (searchbox.dataset.searchFilterName === this.currentFilterView) {
        result = searchbox.value;
      }
    });

    return result.toLowerCase();
  }

  submitForm() {
    this.submitTarget.form.requestSubmit();
  }

  toggleRadio(event) {
    const { filter, text } = event.params;
    this.chosenOptions[filter] = [text];
    if (text === 'Custom') {
      this.dueFromTarget.disabled = false;
      this.dueToTarget.disabled = false;
      this.changeDueRange();
    } else {
      this.removeErrors();
      this.resetCustomState();
    }
  }

  changeDueRange() {
    const to = DateTime.fromISO(this.dueToTarget.value);
    const from = DateTime.fromISO(this.dueFromTarget.value);

    if (this.chosenOptions['Due Date'][0] !== 'Custom') {
      this.setCustomDueDate();
    }

    if (!from.isValid || !to.isValid) {
      this.addError('Both start and end date values are required');
    } else if (to < from) {
      this.addError('End date must occur after start date');
    } else if (to.diff(from).as('days') > 366) {
      this.addError('Maximum 1 year date range allowed');
    } else {
      this.removeErrors();
    }
  }

  changeOlderThan() {
    const olderThanOption = this.filterOptionTargets.find((option) => option.value === 'older_than');
    const olderThanValue = this.olderThanTarget.value;
    const parsedOlderThan = parseInt(olderThanValue, 10);

    if (olderThanValue.length < 1) {
      this.removeErrors();
      olderThanOption.checked = false;
    } else if (Number.isNaN(parsedOlderThan)) {
      this.addError('Days must be a number');
    } else if (parsedOlderThan <= 0) {
      this.addError('Days must be greater than 0');
    } else {
      this.removeErrors();
      this.olderThanTarget.value = parsedOlderThan;
      olderThanOption.checked = true;
    }
  }

  setCustomDueDate() {
    this.chosenOptions['Due Date'] = ['Custom'];

    const customOption = this.filterOptionTargets.find((option) => option.dataset.filterTextParam === 'Custom');
    customOption.checked = true;
  }

  showFilters(event) {
    event.preventDefault();
    this.filterTarget.classList.remove('hidden');
  }

  hideFilters(event) {
    event.preventDefault();
    this.filterTarget.classList.add('hidden');
  }

  showFilterOptions(event) {
    event.preventDefault();
    this.filtersTarget.classList.add('hidden');
    this.backTarget.classList.remove('hidden');

    const { filter } = event.params;

    this.showOptionsForFilter(filter);
  }

  showOptionsForFilter(filter) {
    this.currentFilterView = filter;
    const subFilter = this.filterOptionsTargets.find((element) => element.dataset.filterName === filter);
    subFilter.classList.remove('hidden');
    this.headerTextTarget.innerHTML = filter;
  }

  back(event) {
    event.preventDefault();

    this.updateFilterSummary();

    this.resetState();
  }

  resetState() {
    this.filterOptionsTargets.forEach((element) => {
      element.classList.add('hidden');
    });

    this.currentFilterView = '';
    this.filtersTarget.classList.remove('hidden');
    this.headerTextTarget.innerHTML = 'Filter by';
    this.backTarget.classList.add('hidden');
    this.filterSearchTargets.forEach((searchbox) => {
      const box = searchbox;
      box.value = '';
    });
    this.filterOptionRowTargets.forEach((row) => {
      row.classList.remove('hidden');
    });
  }

  updateFilterSummary() {
    const summaryTarget = this.optionsSummaryTargets.find((summary) => (
      summary.dataset.filterName === this.currentFilterView
    ));

    if (summaryTarget && this.chosenOptions[this.currentFilterView]) {
      summaryTarget.innerHTML = this.chosenOptions[this.currentFilterView].sort().join(', ');
    }
  }

  initializeFilterSummary() {
    let currSummary = null;
    this.optionsSummaryTargets.forEach((summary) => {
      currSummary = summary;
      currSummary.innerHTML = this.chosenOptions[summary.dataset.filterName].sort().join(', ');
    });
  }

  addError(error) {
    this.errorTarget.innerHTML = error;
    this.errorsTarget.classList.remove('hidden');
    this.submitTarget.disabled = true;
    this.submitTarget.classList.add('cursor-not-allowed', 'disabled:opacity-50');
    this.backTarget.disabled = true;
    this.backTarget.classList.add('cursor-not-allowed', 'disabled:opacity-50');
  }

  removeErrors() {
    this.errorsTarget.classList.add('hidden');
    this.submitTarget.disabled = false;
    this.submitTarget.classList.remove('cursor-not-allowed', 'disabled:opacity-50');
    this.backTarget.disabled = false;
    this.backTarget.classList.remove('cursor-not-allowed', 'disabled:opacity-50');
  }

  resetCustomState() {
    this.dueFromTarget.value = null;
    this.dueToTarget.value = null;
  }

  get checkboxes() {
    return this.filterOptionTargets.filter((input) => input.nodeName === 'INPUT');
  }
}
