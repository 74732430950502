import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static classes = ['backgroundOff', 'backgroundOn', 'circleOff', 'circleOn'];

  static targets = ['background', 'switchCircle'];

  static values = { checked: Boolean };

  initialize() {
    this.name = this.element.dataset.name;

    if (this.checkedValue) {
      this.toggleOn();
    } else {
      this.toggleOff();
    }
  }

  toggleOff() {
    this.backgroundTarget.classList.remove(...this.backgroundTarget.classList);
    this.backgroundTarget.classList.add(...this.backgroundOffClasses);
    this.switchCircleTarget.classList.remove(...this.switchCircleTarget.classList);
    this.switchCircleTarget.classList.add(...this.circleOffClasses);
  }

  toggleOn() {
    this.backgroundTarget.classList.remove(...this.backgroundTarget.classList);
    this.backgroundTarget.classList.add(...this.backgroundOnClasses);
    this.switchCircleTarget.classList.remove(...this.switchCircleTarget.classList);
    this.switchCircleTarget.classList.add(...this.circleOnClasses);
  }
}
