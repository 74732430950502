import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'container',
  ];

  initialize() {
    this.selectActiveItems();
  }

  static values = {
    selected: { type: Boolean, default: false },
    itemId: String,
    assigneeId: String,
    year: Number,
    week: Number,
    projectId: String,
  };

  static classes = [
    'selected',
    'deselected',
  ];

  static outlets = [
    'bulk-edit',
  ];

  selectItem(event) {
    if (this.bulkEditOutlet.editingValue === true) {
      this.selectedValue = !this.selectedValue;
      this.dispatch('selectItem', {
        detail: {
          itemId: this.itemIdValue,
          assigneeId: this.assigneeIdValue || null,
          year: this.yearValue,
          week: this.weekValue,
          projectId: this.projectIdValue,
          selected: this.selectedValue,
        },
      });

      // Prevent any further events from happening while performing bulk edits
      event.stopImmediatePropagation();
    }
  }

  deselectItem(_event) {
    this.selectedValue = false;
  }

  selectedValueChanged(isSelected) {
    if (isSelected) {
      this.containerTarget.classList.remove(...this.deselectedClasses);
      this.containerTarget.classList.add(...this.selectedClasses);
    } else {
      this.containerTarget.classList.remove(...this.selectedClasses);
      this.containerTarget.classList.add(...this.deselectedClasses);
    }
  }

  selectActiveItems() {
    this.selectedValue = this.bulkEditOutlet.selectedItems.has(this.itemIdValue);
  }
}
