import { Controller } from '@hotwired/stimulus';
import { DateTime } from 'luxon';

export default class extends Controller {
  static targets = ['time'];

  connect() {
    const updatedAt = DateTime.now();

    this.relativeInterval = setInterval(() => {
      const relativeTime = updatedAt.toRelative();

      this.timeTarget.innerText = `Task updated ${relativeTime}`;
    }, 30000);
  }

  disconnect() {
    clearInterval(this.relativeInterval);
  }
}
