import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['notice', 'message'];

  show() {
    this.noticeTarget.classList.remove('hidden');
  }

  handle(event) {
    const { status: httpStatus, url } = event.detail.fetchResponse.response;

    // Not an error
    if (httpStatus < 400) { return; }

    // This specific error is expected to result in an application re-render.
    if (httpStatus === 422) { return; }

    if (httpStatus === 403 && url.includes('/tasks/')) {
      this.dispatch('task-forbidden');
      this.messageTarget.innerText = 'You do not have permission to view or edit this task.';
    } else {
      this.messageTarget.innerText = 'There was an error with that request. Please refresh and try again.';
    }

    event.preventDefault();
    this.show();
  }

  sortable(_event) {
    this.messageTarget.innerText = 'There was an error with that request. Please refresh and try again.';
    this.show();
  }
}
