import { Controller } from '@hotwired/stimulus';
import StimulusReflex from 'stimulus_reflex';

// This is your ApplicationController.
// All StimulusReflex controllers should inherit from this class.
//
// Learn more at: https://docs.stimulusreflex.com
export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }
}
