import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['filterColumns', 'back'];

  static values = { connected: Boolean };

  connect() {
    this.connectedValue = true;
  }

  showFiltersColumns(event) {
    event.preventDefault();
    this.filterColumnsTarget.classList.remove('hidden');
  }

  hideFiltersColumns(event) {
    event.preventDefault();
    this.filterColumnsTarget.classList.add('hidden');
  }

  submitForm() {
    this.submitTarget.form.requestSubmit();
  }
}
