import { Controller } from '@hotwired/stimulus';
import { toggle, hide } from '../utils/appearance';

export default class SelectController extends Controller {
  static targets = ['hiddenSelect', 'menu', 'optionsList', 'selected'];

  static classes = ['selected'];

  toggle(event) {
    event.preventDefault();
    toggle(this.menuTarget);
  }

  hide(event) {
    if (!this.element.contains(event.target)) {
      hide(this.menuTarget);
    }
  }

  select({ target }) {
    this.hiddenSelectTarget.value = target.dataset.value;
    this.selectedTarget.innerHTML = target.innerHTML;

    const [selected] = this.optionsListTarget.getElementsByClassName(this.selectedClass);
    selected?.classList?.remove(this.selectedClass);

    if (target.dataset.value !== '') {
      target.classList.add(this.selectedClass);
    }

    hide(this.menuTarget);
  }
}
