import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { filterBy: String };

  static targets = ['assignees', 'priorities'];

  switchFilter() {
    if (this.filterByValue === 'Client Assignee') {
      this.assigneesTarget.selectedIndex = -1;
    } else if (this.filterByValue === 'Priority Level') {
      this.prioritiesTargets.forEach((el) => {
        const element = el;
        element.checked = false;
      });
    }
  }
}
