import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['startDateOption', 'allOption', 'startDate'];

  toggleRadio(_event) {
    if (this.allOptionTarget.checked) {
      this.startDateTarget.value = '';
    }
  }
}
