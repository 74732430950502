import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['submit'];

  originalValues = {};

  connect() {
    this.initializeOriginalValues();
    this.addChangeListeners();
  }

  initializeOriginalValues() {
    const form = this.element.closest('form');
    const inputs = form.querySelectorAll('input, select');
    inputs.forEach((input) => {
      this.originalValues[input.name] = input.value;
    });
  }

  addChangeListeners() {
    const form = this.element.closest('form');
    const inputs = form.querySelectorAll('input, select');
    inputs.forEach((input) => {
      input.addEventListener('change', this.handleInputChange.bind(this));
    });
  }

  handleInputChange(event) {
    const input = event.target;
    if (input.value !== this.originalValues[input.name]) {
      this.enableSubmit();
    } else {
      this.disableSubmit();
    }
  }

  enableSubmit() {
    this.submitTarget.disabled = false;
  }

  disableSubmit() {
    this.submitTarget.disabled = true;
  }
}
