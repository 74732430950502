import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  chosenDates = new Set();

  connect() {
    const frequencyValueInput = document.getElementById('modal_every');
    if (frequencyValueInput) {
      frequencyValueInput.addEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }

    const monthlyByDateCheckbox = document.getElementById('monthly_by_date');
    if (monthlyByDateCheckbox) {
      monthlyByDateCheckbox.addEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }

    const intervalSelect = document.getElementById('modal_interval');
    if (intervalSelect) {
      intervalSelect.addEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }

    this.selectDefaultDate();
  }

  disconnect() {
    const frequencyValueInput = document.getElementById('modal_every');
    if (frequencyValueInput) {
      frequencyValueInput.removeEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }

    const monthlyByDateCheckbox = document.getElementById('monthly_by_date');
    if (monthlyByDateCheckbox) {
      monthlyByDateCheckbox.removeEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }

    const intervalSelect = document.getElementById('modal_interval');
    if (intervalSelect) {
      intervalSelect.removeEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }
  }

  toggleDate(event) {
    if (this.chosenDates.has(event.params.date)) {
      this.deselectDate(event);
    } else {
      this.selectDate(event);
    }
  }

  selectDate(event) {
    const { target, params: { date } } = event;
    this.chosenDates.add(date);
    target.classList.add('text-white', 'bg-brand-600');
    target.classList.remove('text-slate-900');
    this.setValuesAndSubmitForm();
  }

  deselectDate(event) {
    const { target, params: { date } } = event;
    this.chosenDates.delete(date);
    target.classList.remove('text-white', 'bg-brand-600');
    target.classList.add('text-slate-900');
    this.setValuesAndSubmitForm();
  }

  setValuesAndSubmitForm() {
    const scheduleSummaryForm = document.getElementById('schedule_summary_form');
    const frequencyValueInput = document.getElementById('modal_every');
    const intervalInput = document.querySelector('input[name="interval"]:checked');
    const everyDataInput = document.getElementById('schedule_summary_every');
    const intervalDataInput = document.getElementById('schedule_summary_interval');
    const scheduleDataInput = document.getElementById('schedule_summary_schedule');
    const monthlyIntervalElement = document.querySelector('[data-schedule-frequency-target="monthlyInterval"]');
    const isMonthlyIntervalVisible = monthlyIntervalElement && !monthlyIntervalElement.classList.contains('hidden');
    const monthlyByDateElement = document.querySelector('[data-schedule-frequency-target="monthlyByDate"]');
    const isMonthlyByDateVisible = monthlyByDateElement && !monthlyByDateElement.classList.contains('hidden');

    if (scheduleSummaryForm && scheduleDataInput && isMonthlyByDateVisible && isMonthlyIntervalVisible) {
      everyDataInput.value = frequencyValueInput.value;
      intervalDataInput.value = intervalInput.value;
      scheduleDataInput.value = Array.from(this.chosenDates).join(',');
      scheduleSummaryForm.requestSubmit();
    }
  }

  selectDefaultDate() {
    const preSelectedDate = document.getElementsByClassName('selected-day')[0];
    if (preSelectedDate) {
      const day = preSelectedDate.getAttribute('data-monthly-by-date-date-param');
      this.chosenDates.add(day);
      this.setValuesAndSubmitForm();
    }
  }
}
