import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['menu'];

  showMenu(_event) {
    this.menuTarget.classList.remove('hidden');
  }

  hideMenu(_event) {
    this.menuTarget.classList.add('hidden');
  }
}
