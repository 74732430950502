import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['weekOf', 'submit'];

  change() {
    if (this.weekOfTarget.value.length > 0) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }
}
