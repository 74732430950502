/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal', 'cleanForm'];

  connect() {
    this.element.addEventListener(
      'modal-custom-listener:openModalViaListener',
      this.openModalViaListener.bind(this),
    );
  }

  disconnect() {
    this.hideAll();
  }

  getIdentifier(event) {
    if (!event?.target) return null;
    let { target } = event;
    while (target && !target.dataset?.modalIdentifier) {
      target = target.parentElement;
    }
    return target?.dataset?.modalIdentifier || null;
  }

  findModal(identifier) {
    return identifier ? this.modalTargets.find((modal) => modal.id === identifier) : this.modalTarget;
  }

  toggle(event) {
    const identifier = this.getIdentifier(event);
    const modalElement = this.findModal(identifier);

    this.toggleModal(modalElement);
  }

  toggleModal(modalElement) {
    if (modalElement.classList.contains('hidden')) {
      this.showModal(modalElement);
    } else {
      this.hideModal(modalElement);
    }
  }

  show(event) {
    if (this.hasCleanFormTarget) {
      this.clearForm(this.cleanFormTarget);
    }

    const identifier = this.getIdentifier(event);
    const modalElement = this.findModal(identifier);
    const cancelHref = this.data.get('cancelHref');

    this.showModal(modalElement, cancelHref);
  }

  showModal(modalElement, cancelHref) {
    modalElement.classList.remove('hidden');

    if (cancelHref) {
      const layoutContainer = document.getElementById('layout-container');
      if (layoutContainer) {
        const scrollPosition = layoutContainer.scrollTop;
        sessionStorage.setItem('layoutContainerScrollPosition', scrollPosition);
      }
    }
  }

  hide(event) {
    const identifier = this.getIdentifier(event);
    const modalElement = this.findModal(identifier);
    this.hideModal(modalElement);
  }

  hideModal(modalElement) {
    if (modalElement) {
      modalElement.classList.add('hidden');
    }
  }

  hideAll() {
    this.modalTargets.forEach((modal) => modal.classList.add('hidden'));
  }

  toggleExternal(event) {
    const externalId = event.params.identifier;
    const externalModal = document.getElementById(externalId);

    if (externalModal.classList.contains('hidden')) {
      this.showModal(externalModal);
    } else {
      this.hideModal(externalModal);
    }
  }

  toggleIfData(event) {
    const { identifier } = event.params;
    const data = document.getElementById(identifier).value;
    if (data) {
      this.toggle(event);
    }
  }

  cancel(event) {
    const identifier = this.getIdentifier(event);
    const modalElement = this.findModal(identifier);
    if (modalElement.classList.contains('hidden')) {
      return;
    }

    const cancelHref = identifier ? this.data.get(`${identifier}-cancelHref`) : this.data.get('cancelHref');
    if (cancelHref) {
      window.location.replace(cancelHref);
    } else {
      this.dispatch('cancel');
      this.hideModal(modalElement);
    }
  }

  clearForm(element) {
    if (element.tagName === 'FORM') {
      element.reset();
    }
  }

  cancelForm(event) {
    const identifier = this.getIdentifier(event);
    this.toggle(event);
    const modalElement = this.findModal(identifier);
    this.clearForms(modalElement);
  }

  clearForms(element) {
    if (element) {
      const form = element.getElementsByTagName('form')[0];
      this.clearForm(form);
    }
  }

  openModalViaListener(event) {
    const modalElement = this.findModal(event.detail.modalIdentifier);

    this.toggleModal(modalElement);
  }
}
