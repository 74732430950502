import { Controller } from '@hotwired/stimulus';
import { hide, show } from '../utils/appearance';

export default class LabelsController extends Controller {
  static targets = [
    'assignable',
    'create',
    'labeling',
    'name',
    'placeholder',
    'selector',
    'unassign',
  ];

  static classes = [
    'even',
    'failure',
    'odd',
  ];

  activate() {
    if (this.hasPlaceholderTarget) hide(this.placeholderTarget);
    show(this.selectorTarget);
    this.unassignTargets.forEach((target) => show(target));

    if (this.hasLabelingTarget) {
      show(this.labelingTarget);
      this.nameTarget.focus();
    }

    document.addEventListener('click', this.#handleClickOutside.bind(this));
  }

  #deactivate() {
    if (this.hasLabelingTarget) hide(this.labelingTarget);
    hide(this.selectorTarget);
    if (this.hasPlaceholderTarget) show(this.placeholderTarget);
    this.unassignTargets.forEach((target) => hide(target));

    document.removeEventListener('click', this.#handleClickOutside.bind(this));
  }

  updateName({ target }) {
    target.classList.remove(this.failureClass);

    const trimmedValue = target.value.trim();
    const normalizedValue = trimmedValue.toLowerCase();
    const classes = [this.oddClass, this.evenClass];

    this.assignableTargets.forEach((item) => {
      if (!trimmedValue || item.dataset.name.includes(normalizedValue)) {
        show(item);
        item.classList.replace(...classes);
        classes.reverse();
      } else {
        hide(item);
      }
    });

    if (!trimmedValue) {
      hide(this.createTarget);
      return;
    }

    show(this.createTarget);
    this.createTarget.disabled = false;
    this.createTarget.innerHTML = `Create <strong>${trimmedValue}</strong>`;
  }

  #handleClickOutside({ target }) {
    if (!this.element.contains(target)) {
      this.#deactivate();
    }
  }
}
