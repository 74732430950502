import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    autoHide: Boolean,
  };

  connect() {
    if (this.autoHideValue) {
      setTimeout(() => {
        this.remove();
      }, 3000);
    }
  }

  remove() {
    this.element.style.transition = 'opacity 0.5s';
    this.element.style.opacity = '0';
    setTimeout(() => {
      this.element.remove();
    }, 500);
  }
}
