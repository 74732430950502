import { Controller } from '@hotwired/stimulus';
import { startFlatfile } from '@flatfile/javascript';
import originalWorkbook from '../workbooks/client_import_workbook';
import improvedImporterWorkbook from '../workbooks/improved_importer_workbook';

export default class extends Controller {
  static values = {
    publishableKey: String,
    environmentId: String,
    userId: String,
    userName: String,
    companyId: String,
    companyName: String,
    useImprovedWorkbook: Boolean,
    priorityLevels: Array,
    services: Array,
    fontFamily: String,
  };

  open() {
    const publishableKey = this.publishableKeyValue;
    const environmentId = this.environmentIdValue;
    let workbook = originalWorkbook;
    if (this.useImprovedWorkbookValue) {
      workbook = improvedImporterWorkbook(this.priorityLevelsValue, this.servicesValue);
    }
    if (!publishableKey && !environmentId) {
      throw new Error(
        'You must provide a publishable key and an environment ID',
      );
    }

    const flatfileOptions = {
      name: `${this.companyNameValue}'s Client Import`,
      publishableKey,
      environmentId,
      workbook,
      closeSpace: {
        operation: 'submitActionFg',
        onClose: () => {
          window.location.reload();
        },
      },
      sidebarConfig: {
        showSidebar: false,
      },
      themeConfig: {
        root: {
          primaryColor: '#4d67ea',
          actionColor: '#4d67ea',
          dangerColor: '#f04747',
          successColor: '#008262',
          warningColor: '#eb7207',
          fontFamily: [this.fontFamilyValue, 'sans-serif'],
        },
        sidebar: {
          backgroundColor: '#4d67ea',
          focusBgColor: '#1c3bd6',
          textColor: '#ffffff',
          focusTextColor: '#ffffff',
          footerTextColor: '#ffffff',
          titleColor: '#ffffff',
          logo: 'https://public-v2-assets.s3.amazonaws.com/Logo-Black-NO-Padding.svg',
        },
        table: {
          column: {
            header: {
              backgroundColor: '#a3b2c9',
              color: '#000000',
            },
          },
        },
      },
      userInfo: {
        userId: this.userIdValue,
        name: this.userNameValue,
        companyId: this.companyIdValue,
        companyName: this.companyNameValue,
      },
    };

    startFlatfile(flatfileOptions);
  }
}
