import { Controller } from '@hotwired/stimulus';

export default class LabelEditController extends Controller {
  static values = { selected: String };

  static targets = ['nameLength', 'submit', 'option'];

  static classes = ['colorSelected'];

  nameChange({ target: { value } }) {
    const nameLength = value.length;

    this.nameLengthTarget.innerHTML = nameLength;
    this.submitTarget.disabled = nameLength === 0;
  }

  colorChange({ params: { color } }) {
    this.selectedValue = color;
  }

  selectedValueChanged(value, previousValue) {
    const newRadio = this.optionTargets.find((target) => target.value === value);
    const oldRadio = this.optionTargets.find((target) => target.value === previousValue);

    if (oldRadio) {
      oldRadio.parentElement.classList.remove(...this.colorSelectedClasses);
    }

    newRadio.parentElement.classList.add(...this.colorSelectedClasses);
  }
}
