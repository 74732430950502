import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['textArea'];

  handleSubmit(event) {
    event.preventDefault();
    this.textAreaTarget.form.requestSubmit();
  }
}
