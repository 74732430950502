const workbook = (priorityLevels, services) => (
  {
    name: 'All Data',
    labels: ['pinned'],
    settings: { trackChanges: true },
    sheets: [
      {
        name: 'Clients',
        slug: 'clients',
        fields: [
          {
            key: 'name',
            type: 'string',
            label: 'Client Name',
            constraints: [{ type: 'required' }],
          },
          {
            key: 'description',
            type: 'string',
            label: 'Client Description',
          },
          {
            key: 'website',
            type: 'string',
            label: 'Client Website',
          },
          {
            key: 'priority_level',
            type: 'enum',
            label: 'Client Priority Level',
            config: {
              options: priorityLevels,
            },
          },
          {
            key: 'client_service_one',
            type: 'enum',
            label: 'Client Service',
            config: {
              options: services,
            },
          },
          {
            key: 'client_service_two',
            type: 'enum',
            label: 'Client Service',
            config: {
              options: services,
            },
          },
          {
            key: 'client_service_three',
            type: 'enum',
            label: 'Client Service',
            config: {
              options: services,
            },
          },
          {
            key: 'client_service_four',
            type: 'enum',
            label: 'Client Service',
            config: {
              options: services,
            },
          },
          {
            key: 'client_service_five',
            type: 'enum',
            label: 'Client Service',
            config: {
              options: services,
            },
          },
          {
            key: 'client_service_six',
            type: 'enum',
            label: 'Client Service',
            config: {
              options: services,
            },
          },
          {
            key: 'primary_contact_name',
            type: 'string',
            label: 'Primary Contact Name',
          },
          {
            key: 'primary_contact_email',
            type: 'string',
            label: 'Primary Contact Email',
          },
          {
            key: 'primary_contact_phone',
            type: 'string',
            label: 'Primary Contact Phone',
          },
          {
            key: 'primary_contact_street',
            type: 'string',
            label: 'Primary Contact Street',
          },
          {
            key: 'primary_contact_city',
            type: 'string',
            label: 'Primary Contact City',
          },
          {
            key: 'primary_contact_state',
            type: 'string',
            label: 'Primary Contact State',
          },
          {
            key: 'primary_contact_postal_code',
            type: 'string',
            label: 'Primary Contact Postal',
          },
          {
            key: 'active',
            type: 'boolean',
            label: 'Active',
          },
        ],
      },
    ],
    actions: [
      {
        operation: 'submitActionFg',
        mode: 'foreground',
        label: 'Submit',
        description: 'Submit clients',
        primary: true,
      },
    ],
  }
);

export default workbook;
