import { Controller } from '@hotwired/stimulus';
import Tribute from 'tributejs';
import Trix from 'trix';

export default class extends Controller {
  static values = {
    mentionsPath: String,
  };

  connect() {
    this.element.addEventListener('trix-initialize', () => {
      this.editorController = this.element.editorController;
      this.editor = this.editorController.editor;

      this.reduceTrixFunctionality();
      this.initializeTribute();
    });
  }

  disconnect() {
    this.tribute.detach(this.element);
  }

  reduceTrixFunctionality() {
    const toolbarElement = this.editorController.toolbarController.element;

    // Hide the toolbar
    toolbarElement.style.display = 'none';

    // Disable keyboard shortcuts
    const buttons = toolbarElement.querySelectorAll('[data-trix-key]');
    for (let i = 0; i < buttons.length; i += 1) {
      delete buttons[i].dataset.trixKey;
    }
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: 'name',
      values: this.fetchUsers,
    });
    this.tribute.attach(this.element);
    this.tribute.range.pasteHtml = this.pasteHtml;
    this.element.addEventListener('tribute-replaced', this.replaced);
  }

  fetchUsers = (text, callback) => {
    const mentionsUrl = new URL(this.mentionsPathValue, document.location);
    mentionsUrl.searchParams.append('query', text);

    // TODO: Use existing websocket instead?
    fetch(mentionsUrl, { headers: { Accept: 'application/json' } })
      .then((response) => response.json())
      .then((users) => callback(users))
      .catch((_error) => callback([]));
  };

  pasteHtml = (html, startPos, endPos) => {
    const position = this.editor.getPosition();
    const width = endPos - startPos;
    this.editor.setSelectedRange([position - width, position]);
    this.editor.deleteInDirection('backward');
  };

  replaced(e) {
    const mention = e.detail.item.original;
    const attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content,
    });
    this.editor.insertAttachment(attachment);
    this.editor.insertString(' ');
  }
}
