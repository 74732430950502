export function hide(element) {
  element.classList.add('hidden');
}

export function show(element) {
  element.classList.remove('hidden');
}

export function toggle(element) {
  element.classList.toggle('hidden');
}
