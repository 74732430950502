import { Controller } from '@hotwired/stimulus';
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ['button', 'menu'];

  static values = { connected: Boolean };

  connect() {
    this.popper = createPopper(this.buttonTarget, this.menuTarget, {
      placement: 'bottom-start',
    });
    this.hide();

    this.connectedValue = true;
  }

  handleOutsideClick = (event) => {
    if (!this.element.contains(event.target)) {
      this.hide();
    }
  };

  toggle(_event) {
    if (this.menuTarget.classList.contains('hidden')) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.menuTarget.classList.remove('hidden');

    window.addEventListener('click', this.handleOutsideClick);

    this.popper.setOptions({
      modifiers: [{ name: 'eventListeners', enabled: true }],
    });
    this.popper.update();
  }

  hide() {
    this.menuTarget.classList.add('hidden');

    window.removeEventListener('click', this.handleOutsideClick);

    this.popper.setOptions({
      modifiers: [{ name: 'eventListeners', enabled: false }],
    });
  }
}
