const workbook = {
  name: 'All Data',
  labels: ['pinned'],
  settings: { trackChanges: true },
  sheets: [
    {
      name: 'Clients',
      slug: 'clients',
      fields: [
        {
          key: 'name',
          type: 'string',
          label: 'Name',
          constraints: [{ type: 'required' }],
        },
        {
          key: 'description',
          type: 'string',
          label: 'Description',
        },
        {
          key: 'website',
          type: 'string',
          label: 'Website',
        },
        {
          key: 'active',
          type: 'boolean',
          label: 'Active',
        },
      ],
    },
  ],
  actions: [
    {
      operation: 'submitActionFg',
      mode: 'foreground',
      label: 'Submit',
      description: 'Submit clients',
      primary: true,
    },
  ],
};

export default workbook;
